import "./App.css";
import OfflineApp from "./components/OfflineApp";
import { useState } from "react";

function App() {
  const [start, setStart] = useState(false);
  return (
    <div id="container">
      <div className="overlay">
        <div className="withinOverlay">
          <div>
            {start ? (
              <div className="board">
                <OfflineApp />
              </div>
            ) : (
              <div className="border">
                <center>
                  <h1 className=""> دوز کوانتومی</h1>
                  <div className="button" onClick={() => setStart(true)}>
                    شروع
                  </div>
                </center>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
